<template>
    <div>
            <b-navbar toggleable="lg"
                type="dark"
                class="navbar-custom p-0 d-flex justify-content-between"
                style="background-color: #aec2b6">
            <b-navbar-brand class="res d-flex align-items-center">
                <img src="@/assets/images/logo/army.png" class="bgImg" alt="" />
                <h5 style="font-size: 2.5rem;
                        margin-left: 10px;
                        font-weight: 600;
                        color: #235123;">
                    AG's Dashboard
                </h5>
            </b-navbar-brand>
            <div class="d-flex align-items-center">
                <h5 style="font-size: 1.5rem;
                        margin-right: 20px;
                        font-weight: 550;
                        color: #235123;">
                    {{ formattedDate }}
                </h5>
                <h5 style="font-size: 1.5rem;
                        margin-right: 20px;
                        font-weight: 550;
                        color: #235123;
                        cursor: pointer;"
                    @click="logoutButtonClick">
                    <feather-icon size="20" icon="LogOutIcon" class="mr-1" />
                    <span>Logout</span>
                </h5>
            </div>
        </b-navbar>

        <br><br>
        <div>
            <b-row class="justify-content-md-center ">
                <b-col cols="12" md="auto">
                    <b-link class=""
                            :to="{ name: 'Admin' }">
                        <b-card class="shadow ">
                            <img :src="require('@/assets/images/newDashboard/afpdashboard.png')" alt="" width="220px" height="200px">

                            <h5 style="font-size:25px; color:black; font-weight:bolder; text-align: center;"> <u>AFP</u> </h5>
                        </b-card>
                    </b-link>
                </b-col>
                <b-col cols="12" md="auto">
                    <b-link href="https://dashboard.limspakistan.org/" target="_blank">
                        <b-card class="shadow ">
                            <img :src="require('@/assets/images/newDashboard/lims1.png')" alt="" width="220px" height="200px">

                            <h5 style="font-size:25px; color:black; font-weight:bolder; text-align: center;"> <u>LIMS</u> </h5>
                        </b-card>
                    </b-link>
                </b-col>
                <b-col cols="12" md="auto">
                    <b-link href="https://decisionhub360.com/" target="_blank">
                        <b-card class="shadow ">
                            <img :src="require('@/assets/images/newDashboard/dha.png')" alt="" width="220px" height="200px">
                            <h5 style="font-size:25px; color:black; font-weight:bolder; text-align: center;"><u>DHA </u></h5>
                        </b-card>
                    </b-link>
                </b-col>
            </b-row>
        </div>
        

    </div>
</template>
<script>
    import {mapGetters, mapActions } from "vuex";
    export default {
        components: {},
         data() {
            return {
                formattedDate: null,
            }
        },
         async mounted() {
            let currentDate = new Date().toJSON().slice(0, 10);
                this.formattedDate = new Date(currentDate).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit'
                });
         },
        methods: {
            ...mapActions({ logout: "appData/logout" }),
            async logoutButtonClick() {
                try {
                    const res = await this.logout();
                    if (res.status === 204) {
                        this.$router.push({ name: "Login" });
                    }
                } catch (error) {
                    console.log(error);
                    // this.displayError(error);
                }
                },
               
        },
         computed: {
                ...mapGetters({
                    getUser: "appData/getUser",
                }),
            },
    };
    
</script>

<style scoped>
.bgImg {
  height: 50px;
  margin: 5px;
  margin-left: 10px;
  margin-right: 5px;
  /* padding-top: 0;
  padding-left: 20px;
  padding-right: 20px; */
  /* width: 100% !important; */
}
.shadow {
        box-shadow: 8px 8px 44px -9px rgba(0,0,0,0.35) !important;
        -webkit-box-shadow: 8px 8px 44px -9px rgba(0,0,0,0.35) !important;
        -moz-box-shadow: 8px 8px 44px -9px rgba(0,0,0,0.35) !important;
        
    }
</style>